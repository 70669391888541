<template>
  <div>

    <b-row>
      
      <b-col
        xl="4"
        cols="12"
      >

        <b-card>

          <h6>Crédito Disponível</h6>
          <h1
            class="text-primary"
          >
            <b-icon
              font-size="15"
              icon="wallet2"
              color="#000"
            />
            {{ saldo }}
            <b-icon
              :class="{ rotating: carregandoSaldo }"        
              icon="arrow-clockwise"
              font-size="18"
              cursor="pointer"
              class="text-dark"
              @click="carregarSaldo()"
            />
          </h1>

          <b-button
            variant="primary"
            size="sm"
            :to="{ name: 'financeiro-recarregar'}"
          >
            <b-icon
              font-size="12"
              icon="plus"
            />
            Recarregar
          </b-button> 

        </b-card>

      </b-col>

      <b-col
        xl="8"
        cols="12"
      >

        <b-card>

          <h6 v-if="formaPagamento == 1">Assinatura</h6>
          <h6 v-else>Última Recarga (Pix)</h6>
          
          <div
            v-if="assinaturaAtiva"
          >

            <b-row v-if="formaPagamento == 1">
              <b-col>
                <b-badge
                  :variant="assinaturaStatusCor(assinatura.statusAssinaturaValor)"
                >
                  {{ assinaturaStatusTexto(assinatura.statusAssinaturaValor) }}
                </b-badge>
              </b-col>
            </b-row>
            
            <b-row class="mt-1">
              <b-col>
                <h5>Plano</h5>
                <span>{{ assinatura.planoNome }} <span v-if="formaPagamento == 1">({{ assinatura.planoCredito }} créditos)</span></span>
                <p class="font-weight-bold">{{ formatarValor(assinatura.planoValor) }}</p>
              </b-col>

              <b-col v-if="formaPagamento == 1">
                <h5>Vencimento</h5>
                <p class="font-weight-bold">{{ formatarData(assinatura.dataVencimento) }}</p>
              </b-col>

              <b-col v-else>
                <h5>Créditos</h5>
                <p class="font-weight-bold">{{ assinatura.planoCredito }} créditos</p>
              </b-col>

              <b-col v-if="formaPagamento == 1">
                <h5>Cartão de Crédito</h5>
                <p class="font-weight-bold">Final {{ assinatura.finalCartao }} <strong class="text-capitalize">({{ assinatura.bandeira }})</strong></p>
              </b-col>

              <b-col>
                <h5>Contratação</h5>
                <p class="font-weight-bold">{{ formatarData(assinatura.dataContratacao) }}</p>
              </b-col>

            </b-row>

            <b-row>
              <b-col>
                <b-button
                  v-if="assinatura.statusAssinaturaValor === 1 && formaPagamento === 1"
                  variant="outline-dark"
                  size="sm"
                  @click="cancelarAssinatura()"
                >
                  Cancelar Assinatura
                </b-button>
              </b-col>
            </b-row>

          </div>

          <div
            v-else
          >
            <b-row class="text-center">
              <b-col>
                <b-alert
                  variant="secondary"
                  show
                  class="mt-1"
                >
                  <div class="alert-body text-center">
                    Você ainda não possui uma assinatura ativa!
                  </div>
                </b-alert>
                <b-button
                  variant="primary"
                  size="sm"
                  :to="{ name: 'financeiro-recarregar'}"
                >
                  Quero ser assinante
                </b-button>
              </b-col>
            </b-row>
          </div>

        </b-card>

      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BAlert, BBadge, BRow, BCol, BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BButton, IconsPlugin,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'

import useJwt from '@/auth/jwt/useJwt'

// import moment from 'moment'
import moment from 'moment-timezone'

export default {
  components: {
    BAlert,
    BBadge,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    IconsPlugin,
    moment,
  },
  data() {
    return {
      saldo: 0,
      carregandoSaldo: false,
      assinaturaAtiva: null,
      formaPagamento: null,
      assinatura: {
      },
    }
  },
  computed: {
   
  },
  created() {
    //this.carregandoSaldo();
  },
  mounted() {

    // Verificar Assinatura
    const usuarioData = JSON.parse(localStorage.getItem('usuarioData'))
    this.assinaturaAtiva = usuarioData.assinaturaAtiva
    this.formaPagamento = usuarioData.formaPagamento

    // Verificar Assinatura

    this.verificarAssinatura();
    this.carregarSaldo();

  },
  methods: {

    assinaturaStatusCor(statusNegociacao) {
      if (statusNegociacao == '0') return 'secondary'
      if (statusNegociacao == '1' || statusNegociacao == '2') return 'success'
      if (statusNegociacao == '3' || statusNegociacao == '4') return 'danger'
      return 'secondary'
    },

    assinaturaStatusTexto(statusNegociacao) {
      if (statusNegociacao == '0') return 'Aguardando Pagamento'
      if (statusNegociacao == '1') return 'Ativa'
      if (statusNegociacao == '2') return 'Aguardando Pagamento'
      if (statusNegociacao == '3') return 'Cancelada'
      if (statusNegociacao == '4') return 'Expirada'
      return 'Aguardando Pagamento'
    },

    formatarData(data) {

      const dataFormatada = moment.utc(data).format('DD/MM/YYYY')
      return dataFormatada

    },

    formatarDataVencimento(data) {

      if(data){
        return moment.utc(data).format('DD/MM/YYYY');
      }

      return "Indeterminado"

    },

    formatarValor(valor) {

      const valorFormatado = (valor / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      return valorFormatado

    },

    getSaldo() {

      const userData = getUserData()
      this.saldo = userData.saldo

    },

    verificarAssinatura() {

      useJwt.compositorAssinatura()
      .then(response => {

        this.assinatura = response.data;
        this.assinatura.pix = this.assinatura.formaPagamentoValor === 0 ? true : false; 
        this.assinaturaAtiva = true;

        // console.log(this.assinatura)

      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {

      })

    },

    // CANCELAR ASSINATURA
    cancelarAssinatura() {
      this.$swal({
        title: 'Deseja cancelar a assinatura?',
        text: 'Não será possível reverter essa ação. Após o cancelamento, você ainda continuará com os créditos que ainda estão disponíveis.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, cancelar!',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          // API
          useJwt.assinaturaCancelar({
            id: this.assinatura.id,
          })
            .then(response => {

              // Recuperar o objeto do localStorage
              var usuarioData = JSON.parse(localStorage.getItem("usuarioData"))

              // Atualizar a propriedade "assinaturaAtiva"
              usuarioData.assinaturaAtiva = false

              // Armazenar o objeto atualizado de volta no localStorage
              localStorage.setItem("usuarioData", JSON.stringify(usuarioData))

              this.$swal({
                title: 'Assinatura Cancelada!',
                text: 'Sua assinatura foi cancelada. Caso você tenha créditos disponíveis ainda poderá utilizá-los.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed) {
                  // REDIRECIONA PARA APROVAÇÕES
                  this.$router.go()
                } else {
                  // REDIRECIONA PARA APROVAÇÕES
                  this.$router.go()
                }
              })
            })
            .catch(error => {
              this.$swal({
                title: 'Ops! Aconteceu um erro.',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
          // API
        } else {

          // CANCELAR SWEET

        }
      }).catch(error => {

      })
    },
    // CANCELAR ASSINATURA

    carregarSaldo() {
      
      this.carregandoSaldo = true

      useJwt.dashboardCompositorCarregarSaldo()
      .then(response => {

        this.saldo = response.data

      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {

        setTimeout(() => {
          this.carregandoSaldo = false
        }, 1000)

      })

    }

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';

@-webkit-keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 1s linear infinite;
}

</style>
